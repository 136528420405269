import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import {
  Highlight,
  InstantSearch,
  SearchBox,
  useHits,
  useInstantSearch
} from 'react-instantsearch-hooks-web';
import { useRouter } from 'next/router';
import type { Hit as BaseHit } from 'instantsearch.js';
import Link from 'next/link';
import pathMap from '~/shared/pathMap';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { ChevronLeft, ChevronRight, Search } from 'react-feather';

import { useBreakpoint } from '~/lib/hooks/useTailwindBreakpoints';

import { useGlobalSearch } from './useGlobalSearch';
import { useSearchClient } from '~/lib/algolia';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller, Navigation, Pagination } from 'swiper';
import { twMerge } from 'tailwind-merge';

type HitType = BaseHit<
  {
    name: string;
    sku?: string;
    vendor: string;
    imageUrl: string;
    categories?: string[];
    categorySlug?: string;
    slug: string;
  } & (
    | {
        type: 'product';
        variantSlug?: string;
      }
    | {
        type: 'solution';
        variantSlug?: never;
      }
  )
>;

export const getProductIndexPrefix = () =>
  `${process.env.LCA_ENV !== 'development' ? 'prod' : 'dev'}_PRODUCTS_`;

const LOCALES_THAT_HAVE_INDEX = ['en', 'de', 'fr', 'it', 'ja', 'ko', 'th', 'vi', 'zh'] as const;

export function useProductsIndexName() {
  const router = useRouter();
  const indexNamePrefix = getProductIndexPrefix();

  const currentLocale = router.locale?.split('-')[0];

  // Some languages don't have their own index, so we use the fallback index
  const localeToUse =
    !currentLocale || !LOCALES_THAT_HAVE_INDEX.includes(currentLocale ?? '') ? 'en' : currentLocale;

  return indexNamePrefix + localeToUse?.toUpperCase();
}

function getHitLinkUrl(hit: HitType) {
  switch (hit.type) {
    case 'product': {
      if (hit.categorySlug === 'bundles') {
        return pathMap.bundle.as([hit.categorySlug, hit.slug]);
      }

      if (hit.variantSlug) {
        return pathMap.productVariant.as([hit.categorySlug!, hit.slug, hit.variantSlug]);
      }
      return pathMap.product.as([hit.categorySlug!, hit.slug]);
    }
    case 'solution': {
      return pathMap.solution.as([hit.slug]);
    }
    default: {
      throw new Error('Invalid hit type: ' + (hit as any).type);
    }
  }
}

type ResultSectionType = ['products', 'solutions'][number];

type ResultSectionProps = {
  title: string;
  allHits: HitType[];
  hasTopDivider?: boolean;
  roundResultImages?: boolean;
  type: ResultSectionType;
};

function ResultSection({ title, allHits, hasTopDivider, roundResultImages, type }: ResultSectionProps) {
  const [hitsPerRow, setHitsPerRow] = useState(4);
  const [inited, setInited] = useState(false);
  SwiperCore.use([Navigation, Pagination]);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const paginationRef = useRef(null);

  const [isInit, setInit] = useState(false);

  const { close: closeGlobalSearch } = useGlobalSearch();
  const { isSm } = useBreakpoint('sm');
  const { isXl } = useBreakpoint('xl');
  const { is2xl } = useBreakpoint('2xl');

  const { isLg } = useBreakpoint('lg');

  // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks

  useEffect(() => {
    if (!inited) {
      if (is2xl) {
        setHitsPerRow(type === 'products' ? 8 : 5);
        setInited(true);
      } else if (isXl) {
        setHitsPerRow(type === 'products' ? 8 : 4);
        setInited(true);
      } else if (isLg) {
        setHitsPerRow(type === 'products' ? 6 : 3);
        setInited(true);
      } else {
        setHitsPerRow(type === 'products' ? 4 : 2);
        setInited(true);
      }
    }
  }, [inited, is2xl, isLg, isSm, isXl, type]);

  const sliderHits = Array.from({ length: Math.ceil(allHits.length / hitsPerRow) }, (_, i) =>
    allHits.slice(i * hitsPerRow, i * hitsPerRow + hitsPerRow)
  );

  if (!allHits.length) return null;

  console.log(sliderHits);

  return (
    <section
      className={classNames(
        'py-6',
        hasTopDivider && 'border-solid border-0 border-t border-t-gray-200 -mx-4 px-4'
      )}
    >
      <h2 className="text-left text-lg font-normal">{title}</h2>
      <div className="flex flex-col">
        <Swiper
          slidesPerView={1}
          modules={[Pagination, Controller]}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current
          }}
          pagination={{
            el: paginationRef.current,
            bulletClass: twMerge('swiper-pagination-bullet', 'bg-brand-darkPurple w-3 h-3'),
            clickable: true
          }}
          onInit={(_) => {
            setInit(true);
          }}
          className="flex flex-col w-full"
        >
          {sliderHits.map((hits, index) => (
            <SwiperSlide className="w-full h-auto" key={index}>
              <div
                className={classNames(
                  'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 my-4 overflow-hidden',
                  type === 'solutions' && '2xl:grid-cols-5'
                )}
              >
                {hits.slice(0, hitsPerRow * 2).map((hit: HitType) => (
                  <Link
                    key={hit.objectID}
                    href={getHitLinkUrl(hit)}
                    className={classNames(
                      'flex text-black no-underline link-group-hover',
                      type == 'solutions' && 'flex-col w-[16rem]'
                    )}
                    onClick={closeGlobalSearch}
                  >
                    <Image
                      className={classNames(
                        'self-center group-image object-contain',
                        roundResultImages && 'rounded-xl',
                        type === 'products'
                          ? 'aspect-square object-contain'
                          : 'object-cover h-[11rem] w-[16rem]'
                      )}
                      src={hit.imageUrl}
                      alt={hit.name}
                      width={type == 'solutions' ? 230 : 75}
                      height={type == 'solutions' ? 100 : 75}
                      priority
                    />
                    <div
                      className={classNames('flex flex-col justify-between', type == 'products' && 'ml-4')}
                    >
                      <Highlight
                        attribute="name"
                        hit={hit}
                        classNames={{
                          highlighted: 'text-white bg-brand-darkPurple',
                          root: 'text-base font-medium overflow-hidden line-clamp-3'
                        }}
                      />
                      <div className="flex flex-col">
                        <Highlight
                          attribute="vendor"
                          hit={hit}
                          classNames={{
                            highlighted: 'text-white bg-brand-darkPurple',
                            root: 'text-sm whitespace-nowrap text-ellipsis overflow-hidden text-black/70'
                          }}
                        />
                        {hit.sku && (
                          <Highlight
                            attribute="sku"
                            hit={hit}
                            classNames={{
                              highlighted: 'text-white bg-brand-darkPurple',
                              root: 'text-sm whitespace-nowrap text-ellipsis overflow-hidden text-black/70 font-italic'
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </SwiperSlide>
          ))}
          {isInit && (
            <div className="flex flex-row self-center space-x-12">
              <div ref={prevRef} className="cursor-pointer z-100">
                <ChevronLeft color="#4D3880" />
              </div>
              <div className="min-w-12 max-w-36" ref={paginationRef}></div>
              <div ref={nextRef} className="cursor-pointer z-100">
                <ChevronRight color="#4D3880" />
              </div>
            </div>
          )}
        </Swiper>
      </div>
    </section>
  );
}

function Hits() {
  const { t } = useTranslation();
  const { hits } = useHits<HitType>();

  const productHits = hits.filter((hit) => hit.type === 'product');
  const solutionHits = hits.filter((hit) => hit.type === 'solution');

  return (
    <Transition
      show={hits.length > 0}
      enter="transition duration-100 ease-out"
      enterFrom="transform -translate-y-1/3 opacity-0"
      enterTo="transform translate-y-0 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform translate-y-0 opacity-100"
      leaveTo="transform -translate-y-1/3 opacity-0"
      as={Fragment}
    >
      <div className="w-full flex flex-col bg-white px-4 pb-6 rounded-b-md overflow-y-auto">
        <ResultSection allHits={productHits} title={t('globalSearch.productsTitle')} type="products" />
        <ResultSection
          allHits={solutionHits}
          title={t('globalSearch.solutionsTitle')}
          hasTopDivider
          roundResultImages
          type="solutions"
        />
      </div>
    </Transition>
  );
}

function CustomSearchBox() {
  const { t } = useTranslation();
  const { results } = useInstantSearch();

  return (
    <div className="relative">
      <SearchBox
        autoFocus
        classNames={{
          submit: 'hidden',
          input: classNames(
            'w-full pr-6 pl-14 py-6 outline-none placeholder:text-black/60 duration-100 ease-in-out rounded-none',
            results.hits.length === 0
              ? 'rounded-b-lg border-0 border-b border-transparent'
              : 'border-solid border-0 border-b border-gray-200'
          ),
          reset: 'hidden'
        }}
        placeholder={t('globalSearch.placeholder')}
      />
      <Search
        className="absolute top-1/2 left-6 text-black/60"
        size={22}
        style={{ transform: 'translateY(-50%)' }}
      />
    </div>
  );
}

export function GlobalSearchOverlay() {
  const { close, isOpen } = useGlobalSearch();
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog open={isOpen} onClose={close} className="absolute h-screen w-screen inset-0 z-[1200]">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        <div className="fixed inset-0">
          <Dialog.Panel className="relative container mx-auto max-h-screen flex flex-col">
            <InstantSearch searchClient={useSearchClient()} indexName={useProductsIndexName()}>
              <CustomSearchBox />
              <Hits />
            </InstantSearch>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
